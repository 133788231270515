<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Convention.Schedule.Discover.Interests">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body class="interest" :is-call="is.saving" :loading="is.loading && is.initialised" v-if="is.initialised">

		<app-content-call v-if="is.saving" icon="loading" title="Just a moment..." text="We're building your game list, generating suggestions, and finding matches... this may take a while."/>

		<template v-if="!is.saving">

		<app-content-box title="Setup" icon="settings">

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name" notes="Give your interest a relevant name so you can easily reference it in the future." :maxlength="64"  />
			<app-input-toggle label="Type" v-model="model.specific" :validation="$v.model.specific" :options="typeOptions" notes="Determine how suggestions will be generated." />
			<app-input-toggle label="Enable Notifications" v-model="model.notifications" :validation="$v.model.notifications" notes="Generate a notification when new event suggestions are available." />
			<app-input-toggle label="Allow Matchmaking" v-model="model.matchmaking" :validation="$v.model.matchmaking" notes="Allow matching with other attendees due to shared interests." />
			<app-input-toggle label="Allow Clashes" v-model="model.clashes" :validation="$v.model.clashes" notes="Allow suggestions that clash with your existing schedule." />
			<app-input-toggle label="Allow Full Events" v-model="model.full" :validation="$v.model.full" notes="Allow suggestions that are already fully seated." />

		</app-content-box>

		<app-content-box title="Event rules" icon="filter">

			<app-input-slider :unstacked="true" theme="blue" label="Duration" sublabel="(minutes)" type="range" :min="0" :max="360" :step="30" :pip="30" v-model="model.filter.setup.duration" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Total seats" :min="1" :max="10" maxText="Any" v-model="model.filter.setup.seats.total" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Available seats" :min="0" :max="9" maxText="Any" v-model="model.filter.setup.seats.available" />
			<app-input-slider :unstacked="true" theme="blue" type="threshold" label="Minimum age" :min="9" minText="Any" :max="21" :step="3" :pip="3" v-model="model.filter.setup.age" />
			<app-input-slider :unstacked="true" theme="blue" type="threshold" label="Minimum experience" :texts="$constants.schedule.experienceSlider" :min="0" :max="4" v-model="model.filter.setup.experience" />

		</app-content-box>

		<app-content-box title="Games" icon="filter" v-if="model.specific">

			<app-input-suggest :multiple="true" placeholder="Search game" image="image" v-model="model.games" api="bgg/game" :validation="$v.model.games" />

		</app-content-box>

		<app-content-box title="Game rules" icon="filter" v-if="!model.specific">

			<app-input-slider :unstacked="true" theme="blue" type="range" label="Recommended count" :min="1" :max="10" maxText="Any" v-model="model.filter.game.count.recommended" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Best count" :min="1" :max="10" maxText="Any" v-model="model.filter.game.count.best" />
			<app-input-slider :unstacked="true" theme="blue" label="Weight" type="range" :min="0" :max="5" :step="0.1" :pip="0.5" v-model="model.filter.game.weight" />
			<app-input-slider :unstacked="true" theme="blue" label="Published year" type="range" :min="1972" :max="2022" :step="1" :pip="10" v-model="model.filter.game.year" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Language independence" :inverse="true" :min="1" :max="5" :texts="$constants.schedule.languageSlider" v-model="model.filter.game.language" />

		</app-content-box>

		<app-content-box title="DT Seals & BGG ratings rules" icon="filter" v-if="!model.specific">

			<app-input-toggle label="Seal of Excellence" v-model="model.filter.rating.seal.excellence" />
			<app-input-toggle label="Seal of Approval" v-model="model.filter.rating.seal.approval" />
			<app-input-toggle label="Recommended" v-model="model.filter.rating.recommended" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Average rating" :min="0" :max="10" v-model="model.filter.rating.community" />
			<app-input-slider :unstacked="true" theme="blue" type="range" label="Rank" :min="0" :max="1000" :step="25" :pip="100" maxText="Any" v-model="model.filter.rating.rank" />

		</app-content-box>

		<app-content-box title="Other rules" icon="filter" v-if="!model.specific">

			<app-input-suggest :stacked="true" v-model="model.filter.other.designers" api="bgg/designer" placeholder="Search designers" />
			<app-input-suggest :stacked="true" v-model="model.filter.other.publishers" api="bgg/publisher" placeholder="Search publishers" />
			<app-input-suggest :stacked="true" v-model="model.filter.other.categories" api="bgg/category" placeholder="Search categories" />
			<app-input-suggest :stacked="true" v-model="model.filter.other.mechanics" api="bgg/mechanic" placeholder="Search mechanics" />
			<app-input-suggest :stacked="true" v-model="model.filter.other.families" api="bgg/family" placeholder="Search families" />

		</app-content-box>

		</template>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			ignoreUnsaved: true,
			model: {
				id: false,
				name: '',
				clashes: 0,
				notifications: 1,
				games: [],
				matchmaking: 1,
				full: 0,
				specific: 0,
				filter: []
			},
			typeOptions: {
				0: 'Rules based',
				1: 'Specific game(s)'
			}
		}

	},

	validations: {
		model: {
			name: {
				required,
				maxLength: maxLength(64)
			}
		}
	},

	created: function() {

		if (this.isNew) this.model.filter = this.$util.copy(this.$constants.filters.events)

	},

	computed: {

		title: function() {

			return (this.isNew) ? 'Add interest' : this.item.name
		
		}

	},

	methods: {

		onDeleteClick: function() {

			this.$router.push({
				name: this.$route.name + '.Delete',
				params: {
					id: this.model.id || this.$route.params.id
				}
			})

		},

		onSaved: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interests'
			})

		}

	}

}

</script>

<style scoped>

</style>